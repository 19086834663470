function TrustPilotCalling() {
  (function () {
    var mf = document.createElement("script");
    mf.type = "text/javascript"; mf.defer = true;
    mf.async = true;
    mf.src = "//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js";
    document.getElementsByTagName("head")[0].appendChild(mf);
    mf.onload = function () {
      var trustbox = document.getElementById('trustbox');
      window.Trustpilot.loadFromElement(trustbox);
    };
  })();


}

var mf = document.createElement("script");
mf.type = "text/javascript"; mf.defer = true;
mf.async = true;
mf.src = "//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js";
document.getElementsByTagName("head")[0].appendChild(mf);
mf.onload = function () {
  var trustbox = document.getElementById('trustbox');
  window.Trustpilot.loadFromElement(trustbox);
};



