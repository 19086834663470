
var mf = document.createElement("script");
mf.type = "text/javascript";
mf.defer = true;
mf.async = true;
mf.src = "https://analytics.skyscanner.net/dv/v1/tag.js";
document.getElementsByTagName("head")[0].appendChild(mf);

function SkyScannerIntigrationPreConversion(stage, price, currency, occurred_at, payment_fee, taxes, fees, check_in, check_out, rooms, guests, skyscanner_redirect_id) {

  window._mfq = window._mfq || [];
  (function () {
    var mf = document.createElement("script");
    mf.type = "text/javascript"; mf.defer = true;
    mf.src = "https://analytics.skyscanner.net/dv/v1/tag.js";
    document.getElementsByTagName("head")[0].appendChild(mf);
  })();
  (function (s, k, y, t) {
    s['SkyscannerAnalyticsDVTag'] = t; s[t] = s[t] || function () { (s[t].buffer = s[t].buffer || []).push(arguments) }; s[t].u = y; var l = k.
      createElement("script"); l.src = y; l.id = t; l.async = 1; var h = k.
        getElementsByTagName("head")[0]; h.appendChild(l);
  })(window, document, 'https://analytics.skyscanner.net/dv/v1/tag.js', 'skyscannerDvTag');
  skyscannerDVTag();

  function skyscannerDVTag() {
    // Initialise SAT with your Tracking Source ID
    skyscannerDvTag('init', 'DV-303266-2');
    // Send a 'pre-conversion' event
    skyscannerDvTag('send', 'pre-conversion', {
      tracking_source_id: 'DV-303266-2',
      skyscanner_redirectid: skyscanner_redirect_id,
      stage: stage,
      price: price,
      currency: currency,
      occurred_at: occurred_at,
      payment_fee: payment_fee,
      taxes: taxes,
      fees: fees,
      check_in: check_in,
      check_out: check_out,
      rooms: rooms,
      guests: guests
    });
  }

}
function SkyScannerIntigrationConversion(conversion_id, transaction_total, currency, occurred_at, payment_fee, hotel_id,
  payment_method, margin, taxes, fees, check_in, check_out, hotel_name, hotel_city, hotel_country, rooms, guests, skyscanner_redirect_id) {

  window._mfq = window._mfq || [];
  (function () {
    var mf = document.createElement("script");
    mf.type = "text/javascript"; mf.defer = true;
    mf.src = "https://analytics.skyscanner.net/dv/v1/tag.js";
    document.getElementsByTagName("head")[0].appendChild(mf);
  })();

  (function (s, k, y, t) {
    s[t] = s[t] || function () { (s[t].buffer = s[t].buffer || []).push(arguments) };
    s[t].u = y;
    var l = k.createElement("script"); l.src = y; l.id = t; l.async = 1; var h = k.getElementsByTagName("head")[0]; h.appendChild(l);
  })(window, document, 'https://analytics.skyscanner.net/dv/v1/tag.js', 'skyscannerDvTag');
  skyscannerDVTag();
  function skyscannerDVTag() {
    // Initialise SAT with your Tracking Source ID
    skyscannerDvTag('init', 'DV-303266-2');
    // Send a 'conversion' event
    skyscannerDvTag('send', 'conversion', {
      tracking_source_id: 'DV-303266-2',
      skyscanner_redirectid: skyscanner_redirect_id,
      event_type: 'conversion',
      conversion_id: conversion_id,
      transaction_total: transaction_total,
      currency: currency,
      occurred_at: occurred_at,
      payment_fee: payment_fee,
      hotel_id: hotel_id,
      payment_method: payment_method,
      margin: margin,
      taxes: taxes,
      fees: fees,
      check_in: check_in,
      check_out: check_out,
      hotel_name: hotel_name,
      hotel_city: hotel_city,
      hotel_country: hotel_country,
      rooms: rooms,
      guests: guests
    });
  }
}



