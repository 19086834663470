
function TrustYouReviewsCalling(trustYouId, Ismocha) {
  const customStyleList = Ismocha ? 'null' : '{ "primaryColor": "#222222","font-family" : "Inter, sans-serif" }';
    var trustScoreConfig = {
      tyId: trustYouId, //"674fa44c-1fbd-4275-aa72-a20f262372cd",
      mapping: {},
      keyAPI: "482897f8-7c7b-4749-b19f-a92da5a960b1",
      scale: "5",
      lang: "en",
      customStyle: customStyleList,
      fromIFrame: true,
      showFilters: true,
      display: 'full'
    };

    // Get the div where you want to embed the TrustScore widget
  var appDiv = document.getElementById("trustReviews");

    // Create a new TrustScore element
    var trustScoreElement = document.createElement("ty-meta-review");

    // Set the attributes based on the configuration
    for (var key in trustScoreConfig) {
      if (trustScoreConfig.hasOwnProperty(key)) {
        trustScoreElement.setAttribute(key, trustScoreConfig[key]);
      }
    }

    // Append the TrustScore element to the app div
    appDiv?.appendChild(trustScoreElement);
}

var mf = document.createElement("script");
mf.type = "text/javascript";
mf.async = true;
mf.src = "//cdn.trustyou.com/v2/hotel/widget-ui/meta-review/meta-review.umd.js";
var mf1 = document.createElement("script");
mf1.type = "text/javascript";
mf1.defer = true;
mf1.src = "//cdn.trustyou.com/v2/hotel/widget-ui/shared/iframeResizerContent/4.3.2/iframeResizer.contentWindow.min.js";
document.getElementsByTagName("head")[0].appendChild(mf);
document.getElementsByTagName("head")[0].appendChild(mf1);



