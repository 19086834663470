function mousflowCalling() {
  window._mfq = window._mfq || [];
  (function () {
    var mf = document.createElement("script");
    mf.type = "text/javascript"; mf.defer = true;
    mf.src = "//cdn.mouseflow.com/projects/0b270e5a-e9e7-49de-a213-8b5aecfff49f.js";
    document.getElementsByTagName("head")[0].appendChild(mf);
  })();

  (function (w, d, s, l, i) {
    w[l] = w[l] || []; w[l].push({
      'gtm.start':
        new Date().getTime(), event: 'gtm.js'
    }); var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
        'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', 'GTM-N23W6D5');

  window._mfq = window._mfq || [];
  (function () {
    var mfnoscript = document.createElement("noscript");
    mfnoscript.type = "text/javascript"; mfnoscript.defer = true;
    mfnoscript.src = "https://www.googletagmanager.com/ns.html?id=GTM-N23W6D5";
    mfnoscript.height = "0";
    mfnoscript.width = "0";
    mfnoscript.style="display:none;visibility:hidden";

    document.getElementsByTagName("body")[0].appendChild(mfnoscript);
  })(); 
}



