function PaymentCalling(processKey, brandId, amount, currency, authorizeOnly, hotelName, url, StripeKey) {
  stripe = Stripe(StripeKey);

  const elementOptions = {
    mode: 'payment',
    amount: roundAmountAwayFromZero(amount),
    currency: currency.toLowerCase(),
    captureMethod: authorizeOnly ?? false ? "manual" : "automatic",
    appearance: {
      theme: 'flat',
      variables: {
        colorPrimaryText: '#262626',
        spacingGridColumn: '20px',
        fontSize: "12px",
        colorText: '#222',
        fontWeightBold: 'true',
        borderRadius: '4px',
        fontFamily: 'inter, sans-serif',
      },
      rules: {
        '.Label': {
          fontWeight: '600',
          fontSize: '12px',
          fontFamily: 'inter, sans-serif',
        }
      }
    }
  };

  const paymentOption = {
    wallets: {
      googlePay: 'auto',
      applePay: 'auto'
    },
    paymentMethodOrder: ['google_pay', 'apple_pay', 'card'],
    layout: {
      type: 'accordion',
      defaultCollapsed: false,
      radios: true,
      spacedAccordionItems: true
    },
    fields: {
      billingDetails: {
        name: 'auto',
        email: 'auto',
        phone: 'auto',
        address: {
          line1: 'auto',
          line2: 'auto',
          city: 'auto',
          state: 'auto',
          postalCode: 'auto',
          country: 'auto'
        },
      }
    }
  }

  const elements = stripe.elements(elementOptions);
  const paymentElement = elements.create('payment', paymentOption);
  paymentElement.mount('#payment-form');

  return elements;
}

async function SubmitPayment(elements, processKey, brandId, amount, currency, hotelName, url) {

  const {error: submitError} = await elements.submit();
  if (submitError) {
    handleError(submitError);
    return;
  }

  const res = await fetch("/api/hotel/makepayment", {
    method: "POST",
    headers: {
      'Accept': 'application/json, text/plain',
      'Content-Type': 'application/json;charset=UTF-8'
    },
    body: JSON.stringify({
      BrandId: brandId,
      Amount: amount,
      Currency: currency,
      ProcessKey: processKey,
      IsAutoPaymentMethod: true
    })
  });

  var response = await res.json();
  const handleError = (error) => {
    window.location.href = url + '/hotel/final-details?Key=' + processKey + '&orderStatus=null' + '&brandId=' + btoa(String(brandId));
  }

  const elementOptions = {
    captureMethod: response.data.authorizeOnly ?? false ? "manual" : "automatic",
  };
  elements.update(elementOptions);

  const {error} = await stripe.confirmPayment({
    elements,
    clientSecret: response.data.clientSecret,
    confirmParams: {
      return_url: url + '/hotel/final-details?Key=' + processKey + '&orderStatus=inprogress' + '&brandId=' + btoa(String(brandId)),
    }
  });

  if (error) {
    handleError(error);
  } else {
    // The payment UI automatically closes with a success animation.
    // Your customer is redirected to your `return_url`.
  }
}

function roundAmountAwayFromZero(number) {
  number = number * 100;
  return (number > 0) ? Math.ceil(number - 0.5) : Math.floor(number + 0.5);
}

var mf = document.createElement("script");
mf.type = "text/javascript";
mf.defer = true;
mf.async = true;
mf.src = "https://js.stripe.com/v3/";
document.getElementsByTagName("head")[0].appendChild(mf);

let stripe;
